import axios from 'axios';

//export const baseURL = 'http://api.fehlebaba.az';
//export const baseURL = 'https://localhost:7283';
export const baseURL = process.env.REACT_APP_BASE_URL || 'https://api.fehlebaba.az';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem('access-token');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const refreshToken = localStorage.getItem('refresh-token');
      if (!refreshToken) {
        throw new Error("Refresh token not found");
      }

      const response = await axiosInstance.post('/api/members/create-access-token-by-refresh-token', { refreshToken });

      if (response.status === 200) {
        const newAccessToken = response.data.data.token;
        localStorage.setItem('access-token', newAccessToken);

        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      }
    } catch (err) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
