import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';


Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AdminDashboard = () => {
  const data1 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'İstifadəçilər',
        data: [10, 20, 30, 40, 50, 60, 70],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: ("Dashboard"),
          },
        ]}
      />
      <div className='row'>
        <div className='col-12'>
          <Line data={data1} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
