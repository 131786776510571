import { City } from "../types/city.type";
import axios from "./axios/_axios";

export const cityService = {
    getAllCities: async () => {
        return await axios.get(`/api/cities`);
    },
    createCity: async (city: City) => {
        return await axios.post(`/api/cities`, city);
    },
    updateCity: async (id: number, city: City) => {
        return await axios.put(`/api/cities/${id}`, city);
    },
    deleteCity: async (id: number) => {
        return await axios.delete(`/api/cities/${id}`);
    },
};
