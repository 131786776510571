import { Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';

const AdminHeader = () => {

    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    return (
        <Header className='d-flex justify-content-center mb-3' style={{ backgroundColor: '#45474B', position: 'sticky', top: 0, zIndex: '999' }}>
            <div className='col-6 col-sm-5 col-md-6'>
                <img onClick={() => handleNavigation('/')} className='col-12 col-md-3' src={process.env.PUBLIC_URL + "/images/logo_yellow.png"} alt="Your Company" style={{ cursor: 'pointer' }} />
            </div>
        </Header>
    )
}

export default AdminHeader
