import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Spin, Row, Upload, message, Select, Divider, Radio, DatePicker } from "antd";
import { memberService } from "../services/member.service";
import { UploadOutlined } from '@ant-design/icons';
import { baseURL } from "../services/axios/_axios";
import { categoryService } from "../services/category.service";
import { cityService } from "../services/city.service";
import { Category, FormattedCategory } from "../types/category.type";
import { City, FormattedCity } from "../types/city.type";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';

const { Option } = Select;

const MasterProfileEditPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [categories, setCategories] = useState<FormattedCategory[]>([]);
  const [cities, setCities] = useState<FormattedCity[]>([]);
  const navigate = useNavigate();

  const formatCategoryTree = useCallback((data: Category[], indentLevel: number = 0): FormattedCategory[] => {
    return data.flatMap((category) => {
      const formattedCategory: FormattedCategory = {
        id: category.id,
        name: category.name,
        isParent: !!category.children && category.children.length > 0,
        indent: indentLevel
      };

      const childrenCategories = category.children ? formatCategoryTree(category.children, indentLevel + 1) : [];
      return [formattedCategory, ...childrenCategories];
    });
  }, []);

  const formatCityTree = useCallback((data: City[], indentLevel: number = 0): FormattedCity[] => {
    return data.flatMap((city) => {
      const formattedCity: FormattedCity = {
        id: city.id,
        name: city.name,
        isParent: !!city.children && city.children.length > 0,
        indent: indentLevel
      };

      const childrenCities = city.children ? formatCityTree(city.children, indentLevel + 1) : [];
      return [formattedCity, ...childrenCities];
    });
  }, []);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await memberService.getMemberDetails();
        const memberData = response.data.data;
        setMember(memberData);

        if (memberData.profileImageUrl) {
          setProfileImage([{
            uid: '-1',
            name: 'profileImage.png',
            status: 'done',
            url: `${baseURL}${memberData.profileImageUrl}`
          }]);
        }

        form.setFieldsValue({
          firstName: memberData.firstName,
          lastName: memberData.lastName,
          patronmicName: memberData.patronmicName,
          birthDate: memberData.birthDate ? dayjs(memberData.birthDate) : null,
          gender: memberData.gender,
          experience: memberData.experience,
          description: memberData.description,
          category: memberData.category?.id,
          categories: memberData.categories?.map((cat: any) => cat.id),
          cities: memberData.cities?.map((city: any) => city.id),
        });
      } catch (error) {
        console.error("Failed to fetch member data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      const response = await categoryService.getAllCategories();
      setCategories(formatCategoryTree(response.data.data));
    };

    const fetchCities = async () => {
      const response = await cityService.getAllCities();
      setCities(formatCityTree(response.data.data));
    };

    fetchMemberData();
    fetchCategories();
    fetchCities();
  }, [form, formatCategoryTree, formatCityTree]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('FirstName', values.firstName);
      formData.append('LastName', values.lastName);
      formData.append('PatronmicName', values.patronmicName);
      formData.append('BirthDate', values.birthDate);
      formData.append('Gender', values.gender);
      formData.append('Experience', values.experience);
      formData.append('Description', values.description);

      formData.append('CategoryId', values.category);

      values.categories.forEach((categoryId: string, index: number) => {
        formData.append(`Categories[${index}]`, categoryId);
      });
      values.cities.forEach((cityId: string, index: number) => {
        formData.append(`Cities[${index}]`, cityId);
      });

      if (selectedFile) {
        formData.append('ProfileImage', selectedFile);
      }


      await memberService.updateMaster(member.id, formData);
      message.success("Profil uğurla yeniləndi!", 1);
      navigate('/profile');
    } catch (error) {
      console.error("Failed to update profile:", error);
      message.error("Profil yenilənməsi uğursuz oldu.", 1);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (info: any) => {
    if (info.fileList.length > 0) {
      setSelectedFile(info.fileList[0].originFileObj);
    } else {
      setSelectedFile(null);
    }
    setProfileImage(info.fileList);
  };


  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center profile-custom master-edit mt-2">
      <Form.Item name="profileImage">
        <Upload
          name="profileImage"
          listType="picture-circle"
          maxCount={1}
          defaultFileList={profileImage}
          beforeUpload={() => false}
          onChange={handleFileChange}
        >
          <Button icon={<UploadOutlined />}></Button>
        </Upload>
      </Form.Item>
      <Form form={form} onFinish={handleSubmit} layout="vertical" style={{ width: "100%", maxWidth: "600px", padding: 10 }}>
        <Form.Item label="Ad" name="firstName">
          <Input placeholder="Ad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Form.Item label="Soyad" name="lastName">
          <Input placeholder="Soyad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Form.Item label="Ata adı" name="patronmicName">
          <Input placeholder="Ata adı" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Form.Item
          name="birthDate"
          label="Ad günü"
        >
          <DatePicker
            placeholder="Doğum tarixi"
            format="DD.MM.YYYY"
            style={{ width: "100%", height: '45px', borderRadius: '12px', fontSize: 'medium' }}
          />
        </Form.Item>

        <Form.Item
          name="gender"
        >
          <Radio.Group optionType="button" buttonStyle="solid" className="col-12 custom-radio-group">
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '12px 0 0 12px', textAlign: 'center', paddingTop: '2px' }} value={1}>Kişi</Radio.Button>
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '0 12px 12px 0', textAlign: 'center', paddingTop: '2px' }} value={2}>Qadın</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item
          name="experience"
          label="Təcrübə"
        >
          <Select style={{ width: "100%", height: '45px', fontSize: 'medium' }}>
            <Option value={1}>1 ildən aşağı</Option>
            <Option value={2}>1 ildən 3 ilə qədər</Option>
            <Option value={3}>3 ildən 5 ilə qədər</Option>
            <Option value={4}>5 ildən artıq</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Əsas iş" name="category">
          <Select defaultValue={member?.category?.id} style={{ minHeight: '45px', borderRadius: '12px', fontSize: 'medium' }}>
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id} style={{ paddingLeft: category.isParent ? 0 : 20, fontWeight: category.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Digər işlər" name="categories">
          <Select
            mode="multiple"
            defaultValue={member?.categories?.map((category: any) => category.id)}
            style={{ minHeight: '45px', borderRadius: '12px', fontSize: 'medium', height: 45 }}
          >
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id} style={{ paddingLeft: category.isParent ? 0 : 20, fontWeight: category.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Şəhərlər" name="cities">
          <Select mode="multiple" defaultValue={member?.cities?.map((city: any) => city.id)} style={{ minHeight: '45px', borderRadius: '12px', fontSize: 'medium', height: 45 }}>
            {cities.map((city) => (
              <Select.Option key={city.id} value={city.id} style={{ paddingLeft: city.isParent ? 0 : 20, fontWeight: city.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
           label="Haqqında"
        >
          <TextArea placeholder="Haqqında" rows={3} style={{ borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Divider />
        <Form.Item>
          <Button type="primary" className="bg-warning" htmlType="submit" block style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}>
            Yadda saxla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MasterProfileEditPage;
