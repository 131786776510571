import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Spin, Row, Upload, message, Divider } from "antd";
import { memberService } from "../services/member.service";
import { UploadOutlined } from '@ant-design/icons';
import { baseURL } from "../services/axios/_axios";

const ProfileEditPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await memberService.getMemberDetails();
        const memberData = response.data.data;
        setMember(memberData);

        if (memberData.profileImageUrl) {
          setProfileImage([
            {
              uid: '-1',
              name: 'profileImage.png',
              status: 'done',
              url: `${baseURL}${memberData.profileImageUrl}`
            }
          ]);
        }

        form.setFieldsValue({
          firstName: memberData.firstName,
          lastName: memberData.lastName,
          patronmicName: memberData.patronmicName,
        });
      } catch (error) {
        console.error("Failed to fetch member data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [form]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('FirstName', values.firstName);
      formData.append('LastName', values.lastName);
      formData.append('PatronmicName', values.patronmicName);

      if (selectedFile) {
        formData.append('ProfileImage', selectedFile);
      }

      await memberService.updateMember(member.id, formData);
      message.success("Profil uğurla yeniləndi!", 1);
      navigate('/profile');
    } catch (error) {
      console.error("Failed to update profile:", error);
      message.error("Profil yenilənməsi uğursuz oldu.", 1);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (info: any) => {
    if (info.fileList.length > 0) {
      setSelectedFile(info.fileList[0].originFileObj);
    } else {
      setSelectedFile(null);
    }
    setProfileImage(info.fileList);
  };

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center profile-custom mt-2">
      <Form.Item name="profileImage">
        <Upload
          name="profileImage"
          listType="picture-circle"
          maxCount={1}
          defaultFileList={profileImage}
          beforeUpload={() => false}
          onChange={handleFileChange}
        >
          <Button icon={<UploadOutlined />}></Button>
        </Upload>
      </Form.Item>
      <Form form={form} onFinish={handleSubmit} layout="vertical" style={{ width: "100%", maxWidth: "600px" }}>
        <Form.Item label="Ad" name="firstName">
          <Input placeholder="Ad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Form.Item label="Soyad" name="lastName">
          <Input placeholder="Soyad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Form.Item label="Ata adı" name="patronmicName">
          <Input placeholder="Ata adı" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
        </Form.Item>
        <Divider />

        <Form.Item>
          <Button type="primary" className="bg-warning" htmlType="submit" block style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}>
            Yadda saxla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileEditPage;
