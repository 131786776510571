import { useState } from "react";
import { Card, Rate, Modal, Button } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt, faPhone, faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { baseURL } from "../../services/axios/_axios";

const { Meta } = Card;

const MemberCard = ({ member, isFavorite, onFavoriteToggle }: { member: any, isFavorite: boolean, onFavoriteToggle: (member: any) => void }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCall = () => {
        window.location.href = `tel:${member.phoneNumber}`;
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleCardClick = () => {
        if (isMobile) {
            navigate(`/employee-detail/${member.id}`);
        }
    };

    const profileImageUrl = member.profileImageUrl
        ? `${baseURL}${member.profileImageUrl}`
        : "/images/avatar.jpg";

    return (
        <div key={member.id} className={isMobile ? 'mobile-card-container' : ''}>
            <Card
                style={{ width: '100%', boxShadow: '0px 0px 8px 3px rgba(0,0,0,0.05)', position: 'relative' }}
                cover={
                    <img
                        alt={`${member.firstName} ${member.lastName}`}
                        src={profileImageUrl}
                        className={`employee-card-img ${isMobile ? 'mobile' : 'desktop'}`}
                        onClick={handleCardClick}
                    />
                }
                actions={!isMobile ? [
                    <FontAwesomeIcon icon={faPhone} key="phone" onClick={showModal} />,
                    <FontAwesomeIcon onClick={() => navigate(`/employee-detail/${member.id}`)} icon={faExpandAlt} />,
                    <FontAwesomeIcon
                        icon={isFavorite ? faSolidHeart : faRegularHeart}
                        key="favorite"
                        onClick={() => onFavoriteToggle(member)}
                        className={isFavorite ? "text-danger" : ""}
                    />
                ] : []}
                onClick={isMobile ? handleCardClick : undefined} 
            >
                <Rate
                    allowHalf
                    disabled
                    value={member.averageRating}
                    style={{ scale: '0.6', display: 'flex', justifyContent: 'center', marginBottom: '5px' }}
                />
                <Meta
                    title={`${member.firstName} ${member.lastName}`}
                    className="mb-2"
                    description={
                        <>
                            <div>{member.category?.name ?? '_'}</div>
                            <div>{member.cities?.[0]?.name ?? '_'}</div>
                        </>
                    }
                />
                <Modal
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    centered
                >
                    <Button type='dashed' className="col-12 bg-light mt-5" style={{ height: '50px', fontSize: '20px', fontWeight: '600' }} onClick={handleCall}>
                        {member.phoneNumber}
                    </Button>
                </Modal>
                {isMobile && (
                    <FontAwesomeIcon
                        icon={isFavorite ? faSolidHeart : faRegularHeart}
                        key="favorite"
                        onClick={(e) => {
                            e.stopPropagation();
                            onFavoriteToggle(member);
                        }}
                        className={`favorite-icon ${isFavorite ? "text-danger bg-light" : "text-secondary bg-light"}`}
                        style={{borderRadius: '80% 80% 100% 100%', padding: '5px', fontSize: 20}}
                    />
                )}
            </Card>
        </div>
    );
};

export default MemberCard;
