import React, { useEffect, useState } from 'react';
import { List, Input, Button, Typography, Checkbox, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Category } from '../../types/category.type';
import { categoryService } from '../../services/category.service';
import { Drawer } from 'antd/lib';
import Cookies from 'js-cookie';

const { Text } = Typography;
const { Option } = Select;

interface CategoriesProps {
    onChange?: (selectedRegisterCategories: string[]) => void;
}

const RegisterCategories: React.FC<CategoriesProps> = ({ onChange = () => { } }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedRegisterCategories, setselectedRegisterCategories] = useState<string[]>(() => {
        const cookieData = Cookies.get('selectedRegisterCategories');
        return cookieData ? JSON.parse(cookieData) : [];
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await categoryService.getAllCategories();
                setCategories(response.data.data);
            } catch (error) {
            }
        };

        getCategories();
    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen((prev) => !prev);
    };

    const transformCategories = (categories: Category[]): { label: JSX.Element; value: string; disabled?: boolean }[] => {
        const transformedOptions: { label: JSX.Element; value: string; disabled?: boolean }[] = [];

        const addCategoryWithChildren = (category: Category, level: number) => {
            const isParent = category.children.length > 0;
            transformedOptions.push({
                label: (
                    <Text style={{ marginLeft: level * 20, fontWeight: isParent ? '600' : '400' }}>
                        {category.name}
                    </Text>
                ),
                value: category.id,
                disabled: false,
            });
            category.children.forEach(child => addCategoryWithChildren(child, level + 1));
        };

        categories.forEach(category => addCategoryWithChildren(category, 0));
        return transformedOptions;
    };

    const options = transformCategories(categories);

    useEffect(() => {
        const allCategoryIds = categories.flatMap(category => [category.id, ...getAllDescendantIds(category)]);
        if (selectedRegisterCategories.length === allCategoryIds.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedRegisterCategories, categories]);

    const getAllDescendantIds = (category: Category): string[] => {
        const descendants: string[] = [];
        const addDescendants = (c: Category) => {
            c.children.forEach(child => {
                descendants.push(child.id);
                addDescendants(child);
            });
        };
        addDescendants(category);
        return descendants;
    };

    const handleSelectAllChange = (e: any) => {
        if (e.target.checked) {
            const allCategoryIds = categories.flatMap(category => [category.id, ...getAllDescendantIds(category)]);
            setselectedRegisterCategories(allCategoryIds);
        } else {
            setselectedRegisterCategories([]);
        }
        setSelectAll(e.target.checked);
    };

    const handleCategorySelection = (categoryId: string) => {
        setselectedRegisterCategories(prevSelected => {
            let newSelected = [...prevSelected];

            const toggleSelection = (id: string, select: boolean) => {
                if (select) {
                    if (!newSelected.includes(id)) newSelected.push(id);
                } else {
                    newSelected = newSelected.filter(selectedId => selectedId !== id);
                }
            };

            const category = findCategoryById(categories, categoryId);
            if (category) {
                const descendantIds = getAllDescendantIds(category);
                const select = !newSelected.includes(categoryId);
                toggleSelection(categoryId, select);
                descendantIds.forEach(descendantId => toggleSelection(descendantId, select));
            }

            updateParentSelection(newSelected, categoryId);

            return newSelected;
        });
    };

    const updateParentSelection = (selectedIds: string[], categoryId: string) => {
        const parentCategory = findParentCategory(categories, categoryId);
        if (parentCategory) {
            const allChildrenSelected = parentCategory.children.every(child => selectedIds.includes(child.id));
            if (allChildrenSelected) {
                if (!selectedIds.includes(parentCategory.id)) {
                    selectedIds.push(parentCategory.id);
                }
            } else {
                selectedIds = selectedIds.filter(id => id !== parentCategory.id);
            }
            updateParentSelection(selectedIds, parentCategory.id);
        }
    };

    const findCategoryById = (categories: Category[], id: string): Category | undefined => {
        for (const category of categories) {
            if (category.id === id) return category;
            const childCategory = findCategoryById(category.children, id);
            if (childCategory) return childCategory;
        }
        return undefined;
    };

    const findParentCategory = (categories: Category[], id: string): Category | undefined => {
        for (const category of categories) {
            if (category.children.some(child => child.id === id)) {
                return category;
            }
            const parentCategory = findParentCategory(category.children, id);
            if (parentCategory) return parentCategory;
        }
        return undefined;
    };

    const handleClearSelection = () => {
        setselectedRegisterCategories([]);
        setSelectAll(false);
    };

    const handleOk = () => {
        setIsDrawerOpen(false);
    };

    const getSelectedCategoryNames = () => {
        const findCategoryNameById = (categories: Category[], id: string): string | undefined => {
            for (const category of categories) {
                if (category.id === id) return category.name;
                const childCategoryName = findCategoryNameById(category.children, id);
                if (childCategoryName) return childCategoryName;
            }
            return undefined;
        };

        return selectedRegisterCategories.map(id => findCategoryNameById(categories, id)).filter(name => name !== undefined).join(', ');
    };

    useEffect(() => {
        Cookies.set("selectedRegisterCategories", JSON.stringify(selectedRegisterCategories), { expires: 7 });
        onChange(selectedRegisterCategories);
    }, [selectedRegisterCategories, onChange]);

    return (
        <div>
            {isMobile ? (
                <Input
                    readOnly
                    className='input-focus-remove'
                    placeholder='Digər bacarıqlar...'
                    value={getSelectedCategoryNames()}
                    onClick={() => setIsDrawerOpen(true)}
                    suffix={selectedRegisterCategories.length > 0 ? <Button onClick={handleClearSelection}>x</Button> : null}
                    style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
            ) : (
                <Select
                    mode="multiple"
                    placeholder="Dİgər bacarıqlar..."
                    className='input-focus-remove text-start'
                    value={selectedRegisterCategories}
                    onChange={setselectedRegisterCategories}
                    style={{ width: '100%', height: '45px', borderRadius: '12px' }}
                    maxTagCount={2}
                >
                    {options.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            )}

            {isMobile && (
                <Drawer
                    placement="bottom"
                    size='large'
                    onClose={toggleDrawer}
                    open={isDrawerOpen}
                    style={{ borderRadius: '20px 20px 0 0' }}
                >
                    <div style={{ paddingBottom: '50px' }}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <List.Item
                                onClick={() => handleSelectAllChange({ target: { checked: !selectAll } })}
                                className='rounded col-12 col-md-3 m-md-2'
                                style={{
                                    backgroundColor: selectAll ? '#e0e0e0' : '#F4F4F4', cursor: 'pointer', display: 'flex',
                                    justifyContent: 'space-between', alignItems: 'center', padding: '15px'
                                }}
                            >
                                <Text style={{ fontWeight: '600' }}>Hamısı</Text>
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                            </List.Item>
                        </div>
                        <List
                            dataSource={options}
                            renderItem={option => (
                                <List.Item
                                    onClick={() => handleCategorySelection(option.value)}
                                    className='mx-md-2'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: selectedRegisterCategories.includes(option.value) ? '#F4F4F4' : 'transparent',
                                        cursor: 'pointer',
                                        padding: '15px'
                                    }}
                                >
                                    {option.label}
                                    <Checkbox
                                        checked={selectedRegisterCategories.includes(option.value)}
                                        onChange={() => handleCategorySelection(option.value)}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#fff',
                        padding: '10px',
                        boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
                        zIndex: 1000
                    }}>
                        <Button className='col-12 col-md-2 bg-secondary' style={{ height: '50px', fontSize: 'large' }} type="primary" onClick={handleOk}>
                            Hazırdır
                        </Button>
                    </div>
                </Drawer>

            )}
        </div>
    );
};

export default RegisterCategories;
