import React, { useEffect, useState } from 'react';
import { List, Input, Typography, Button, Drawer } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Category } from '../../types/category.type';
import { categoryService } from '../../services/category.service';
import { Select } from 'antd/lib';

const { Text } = Typography;
const { Option } = Select;

interface OneCategoryProps {
    selectedCategory: string | null;
    onChange: (categoryId: string | null) => void;
}

const OneCategory: React.FC<OneCategoryProps> = ({ selectedCategory, onChange }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectedOneCategory, setSelectedOneCategory] = useState<string[]>([]);

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await categoryService.getAllCategories();
                setCategories(response.data.data);
            } catch (error) {
            }
        };

        getCategories();
    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen((prev) => !prev);
    };

    const transformCategories = (categories: Category[]): { label: JSX.Element; value: string; disabled?: boolean }[] => {
        const transformedOptions: { label: JSX.Element; value: string; disabled?: boolean }[] = [];

        const addCategoryWithChildren = (category: Category, level: number) => {
            const isParent = category.children.length > 0;
            transformedOptions.push({
                label: (
                    <Text style={{ marginLeft: level * 20, fontWeight: isParent ? '600' : '400' }}>
                        {category.name}
                    </Text>
                ),
                value: category.id,
                disabled: false,
            });
            category.children.forEach(child => addCategoryWithChildren(child, level + 1));
        };

        categories.forEach(category => addCategoryWithChildren(category, 0));
        return transformedOptions;
    };

    const options = transformCategories(categories);

    const handleCategorySelection = (categoryId: string) => {
        onChange(categoryId);
        setIsDrawerOpen(false);
    };

    const getSelectedCategoryName = () => {
        const findCategoryNameById = (categories: Category[], id: string): string | undefined => {
            for (const category of categories) {
                if (category.id === id) return category.name;
                const childCategoryName = findCategoryNameById(category.children, id);
                if (childCategoryName) return childCategoryName;
            }
            return undefined;
        };

        return selectedCategory ? findCategoryNameById(categories, selectedCategory) : '';
    };

    return (
        <div>


            {isMobile ? (
                <Input
                    readOnly
                    className='input-focus-remove'
                    placeholder='Əsas iş seçin...'
                    value={getSelectedCategoryName()}
                    onClick={() => setIsDrawerOpen(true)}
                    suffix={selectedCategory ? <Button onClick={() => onChange(null)}>x</Button> : null}
                    style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
            ) : (
                <Select
                    placeholder="Əsas iş seçin..."
                    className='input-focus-remove text-start'
                    value={selectedOneCategory}
                    onChange={setSelectedOneCategory}
                    style={{ width: '100%', height: '45px', borderRadius: '12px' }}
                    maxTagCount={2}
                >
                    {options.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            )}


            {isMobile && (
                <Drawer
                    placement="bottom"
                    size='large'
                    onClose={toggleDrawer}
                    open={isDrawerOpen}
                    style={{ borderRadius: '20px 20px 0 0' }}
                >
                    <List
                        dataSource={options}
                        renderItem={option => (
                            <List.Item
                                onClick={() => handleCategorySelection(option.value)}
                                className='mx-md-2'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: selectedCategory === option.value ? '#F4F4F4' : 'transparent',
                                    cursor: 'pointer',
                                    padding: '15px'
                                }}
                            >
                                {option.label}
                            </List.Item>
                        )}
                    />
                </Drawer>

            )}

        </div>
    );
};

export default OneCategory;
