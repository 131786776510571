import React from 'react';
import { Route, Routes, Navigate, BrowserRouter as Router, useLocation } from 'react-router-dom';
import './App.css';
import './index.css';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import { AuthProvider, useAuth } from './store/AuthContext';
import RegisterPage from './pages/RegisterPage';
import HeaderComponent from './components/header/Header';
import LeftBanner from './components/banners/LeftBanner';
import RightBanner from './components/banners/RightBanner';
import EmployeeDetailPage from './pages/EmployeeDetailPage';
import BottomMobileMenu from './components/bottom-mobile-menu/BottomMobileMenu';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminLayout from './layout/AdminLayout';
import AdminHomePage from './pages/admin/admin-main-page/AdminHomePage';
import WorkerRegisterPage from './pages/WorkerRegisterPage';
import FavoritesPage from './pages/FavoritesPage';
import { FloatButton } from 'antd';
import ProfileEditPage from './pages/ProfileEditPage';
import MasterProfileEditPage from './pages/MasterProfileEditPage';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <AppContent />
        </div>
      </Router>
    </AuthProvider>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      <ScrollToTop />
      {!isAdminRoute && <HeaderComponent />}
      <div className="row m-0" style={{ paddingBottom: '70px' }}>
        {!isAdminRoute && (
          <div className="d-none d-md-block col-md-2 p-0">
            <LeftBanner />
          </div>
        )}
        <div className={isAdminRoute ? 'col-12 p-0' : 'col-12 col-md-8 p-1'}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/favorites" element={<FavoritesPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/worker-register" element={<WorkerRegisterPage />} />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route path="/profile-edit" element={<PrivateRoute><ProfileEditPage /></PrivateRoute>} />
            <Route path="/master-profile-edit" element={<PrivateRoute><MasterProfileEditPage /></PrivateRoute>} />
            <Route path="/employee-detail/:id" element={<EmployeeDetailPage />} />
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route path="login" element={<AdminLoginPage />} />
              <Route path="home" element={<AdminHomePage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        {!isAdminRoute && (
          <div className="d-none d-md-block col-md-2 p-0">
            <RightBanner />
          </div>
        )}
      </div>
      {!isAdminRoute && <BottomMobileMenu />}
      <FloatButton.BackTop style={{ marginBottom: 30, scale: '1.2' }} />
    </>
  );
};

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

export default App;
