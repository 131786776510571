import React, { useState } from "react";
import { Form, Input, Button, message, Upload, DatePicker, Select, Radio } from "antd";
import { useNavigate } from "react-router-dom";
import { memberService } from "../services/member.service";
import { WorkerRegisterRequestType } from "../types/member.type";
import { UploadOutlined } from '@ant-design/icons';
import OneCategory from "../components/search-bar/OneCategory";
import TextArea from "antd/es/input/TextArea";
import RegisterCategories from "../components/register-components/RegisterCategories";
import RegisterCities from "../components/register-components/RegisterCities";

const { Option } = Select;

const WorkerRegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedRegisterCategories, setSelectedRegisterCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedRegisterCities, setSelectedRegisterCities] = useState<string[]>([]);

  const formatPhoneNumber = (phoneNumber: string) => {
    const digits = phoneNumber.replace(/[^\d]/g, '');
    return `${digits.slice(0, 3)} ${digits.slice(3, 5)} ${digits.slice(5, 8)} ${digits.slice(8, 10)}`;
  };

  const onFinish = async (values: WorkerRegisterRequestType & { prefix: string }) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof WorkerRegisterRequestType];
        if (key === 'ProfileImage' && fileList.length > 0) {
          const file = fileList[0].originFileObj;
          if (file) {
            formData.append(key, file);
          }
        } else if (value !== null && value !== undefined) {
          if (key === 'PhoneNumber') {
            const fullPhoneNumber = `+994 ${values.prefix} ${formatPhoneNumber(value as string)}`.trim();
            formData.append(key, fullPhoneNumber);
          } else {
            if (Array.isArray(value)) {
              value.forEach((v) => formData.append(`${key}[]`, v));
            } else {
              formData.append(key, value as string);
            }
          }
        }
      });

      selectedRegisterCategories.forEach((categoryId) => formData.append('CategoryIds[]', categoryId));
      selectedRegisterCities.forEach((cityId) => formData.append('CityIds[]', cityId));

      const response = await memberService.workerRegister(formData);
      if (response.status === 200) {
        message.success("Qeydiyyat uğurla tamamlandı!", 1);
        navigate("/login");
      } else {
        message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
      }
    } catch (error: any) {
      if (error.response) {
        const errorData = error.response.data;

        if (error.response.status === 400) {
          if (Array.isArray(errorData) && errorData.length > 0) {
            message.error(errorData[0], 1);
          } else if (typeof errorData.message === 'string') {
            message.error(errorData.message, 1);
          } else {
            message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
          }
        } else {
          message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
        }
      } else {
        message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
      }
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ height: '45px', width: 70 }}>
        <Option value="50">050</Option>
        <Option value="51">051</Option>
        <Option value="55">055</Option>
        <Option value="70">070</Option>
        <Option value="77">077</Option>
        <Option value="10">010</Option>
        <Option value="99">099</Option>
        <Option value="60">060</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="mt-4 col-12 col-md-8" style={{ margin: "0 auto", padding: "20px" }}>
      <Form
        name="register_form"
        initialValues={{ remember: true, prefix: "50" }}
        onFinish={onFinish}
        className="register_form"
      >
        {/* Profile Image Upload */}
        <Form.Item
          name="ProfileImage"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file) => {
              setFileList([file]);
              return false;
            }}
            fileList={fileList}
            listType="picture-circle"
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}></Button>
          </Upload>
        </Form.Item>

        <div className="row">
          {/* First Name */}
          <Form.Item
            name="FirstName"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Adınızı daxil edin!" }]}
          >
            <Input placeholder="Ad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            name="LastName"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Soyadınızı daxil edin!" }]}
          >
            <Input placeholder="Soyad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        <div className="row">
          {/* Patronymic Name */}
          <Form.Item
            name="PatronmicName"
            className="col-12 col-md-6"
          >
            <Input placeholder="Ata adı" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>

          {/* Birth Date */}
          <Form.Item
            name="BirthDate"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Doğum tarixinizi daxil edin!" }]}
          >
            <DatePicker
              placeholder="Doğum tarixi"
              format="DD.MM.YYYY"
              style={{ width: "100%", height: '45px', borderRadius: '12px' }}
            />
          </Form.Item>
        </div>

        <div className="row">
          {/* Gender */}
          <Form.Item
            name="Gender"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "" }]}
          >
            <Radio.Group optionType="button" buttonStyle="solid" className="col-12 custom-radio-group">
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '12px 0 0 12px', textAlign: 'center', paddingTop: '2px' }} value={1}>Kişi</Radio.Button>
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '0 12px 12px 0', textAlign: 'center', paddingTop: '2px' }} value={2}>Qadın</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* Experience */}
          <Form.Item
            name="Experience"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Təcrübənizi seçin!" }]}
          >
            <Select placeholder="Təcrübənizi seçin" style={{ width: "100%", height: '45px', borderRadius: '12px' }}>
              <Option value={1}>1 ildən aşağı</Option>
              <Option value={2}>1 ildən 3 ilə qədər</Option>
              <Option value={3}>3 ildən 5 ilə qədər</Option>
              <Option value={4}>5 ildən artıq</Option>
            </Select>
          </Form.Item>
        </div>

        <div className="row">
          {/* Category */}
          <Form.Item
            name="CategoryId"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "" }]}
          >
            <OneCategory selectedCategory={selectedCategory} onChange={setSelectedCategory} />
          </Form.Item>

          {/* Multiple Categories */}
          <Form.Item
            name="Categories"
            className="col-12 col-md-6"
          >
            <RegisterCategories onChange={setSelectedRegisterCategories} />
          </Form.Item>
        </div>

        <div className="row">
          {/* Cities */}
          <Form.Item
            name="Cities"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "" }]}
          >
            <RegisterCities onChange={setSelectedRegisterCities} />
          </Form.Item>

          {/* Description */}
          <Form.Item
            name="Description"
            className="col-12 col-md-6"
          >
            <TextArea placeholder="Qeyd" rows={3} style={{ borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        <div className="row">
          {/* Phone Number */}
          <Form.Item
            name="PhoneNumber"
            className="col-12 col-md-4"
            rules={[{ required: true, message: "Telefon nömrənizi daxil edin!" }]}
          >
            <Input
              addonBefore={prefixSelector}
              type="tel"
              placeholder="Telefon nömrəsi"
              style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
              maxLength={7}
            />
          </Form.Item>

          {/* Password */}
          <Form.Item
            name="Password"
            className="col-12 col-md-4"
            rules={[{ required: true, message: "Şifrənizi daxil edin!" }]}
            hasFeedback
          >
            <Input.Password placeholder="Şifrə" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>

          {/* Confirm Password */}
          <Form.Item
            name="ConfirmPassword"
            className="col-12 col-md-4"
            dependencies={['Password']}
            hasFeedback
            rules={[
              { required: true, message: "Şifrənizi təkrar daxil edin!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Şifrələr uyğun deyil!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Təkrar şifrə" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" className='bg-warning mb-3' htmlType="submit" style={{ width: "100%", height: '45px', borderRadius: '12px', fontSize: 'medium' }}>
            Qeydiyyatdan Keç
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WorkerRegisterPage;
