import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import axiosInstance from "../services/axios/_axios";

interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkToken = async () => {
      const accessToken = localStorage.getItem('access-token');
      const refreshToken = localStorage.getItem('refresh-token');

      if (accessToken) {
        try {
          await axiosInstance.get('/api/members/details');
          setIsAuthenticated(true);
        } catch (error) {
          if (refreshToken) {
            try {
              const response = await axiosInstance.post('/api/members/create-access-token-by-refresh-token', { refreshToken });
              localStorage.setItem('access-token', response.data.data.token);
              setIsAuthenticated(true);
            } catch (err) {
              setIsAuthenticated(false);
              localStorage.removeItem('access-token');
              localStorage.removeItem('refresh-token');
              window.location.href = '/login';
            }
          } else {
            setIsAuthenticated(false);
            window.location.href = '/login';
          }
        }
      } else {
        setIsAuthenticated(false);
      }

      setLoading(false);
    };

    checkToken();
  }, []);

  const login = () => setIsAuthenticated(true);
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    window.location.href = '/login';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
