import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { HomeOutlined, UserOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const BottomMobileMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showMenu, setShowMenu] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setShowMenu(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    if (!isMobile) return null;

    // const handleBeforeNavigation = () => {
    //     navigate(-1);
    // };

    // const handleNextNavigation = () => {
    //     navigate(+1);
    // };

    const isActive = (path: string) => location.pathname === path;

    return (
        <div className={`bottom-mobile-menu ${showMenu ? 'visible' : 'hidden'}`}>
            {/* <div>
                <Button
                    type="link"
                    className="text-dark menu-button"
                    onClick={handleBeforeNavigation}
                >
                    <ArrowLeftOutlined style={{ fontSize: '2em', color: '#FFF', marginRight: 15 }} />
                </Button>
            </div> */}
            <div>
                <Button
                    type="link"
                    className="text-dark menu-button"
                    onClick={() => handleNavigation('/favorites')}
                >
                    {isActive('/favorites') ? (
                        <HeartFilled style={{ fontSize: '2em', color: '#dc3545' }} />
                    ) : (
                        <HeartOutlined style={{ fontSize: '2em', color: 'white' }} />
                    )}
                </Button>

            </div>
            <div className="home-icon-wrapper">
                <Button
                    type="link"
                    className="text-dark home-button"
                    onClick={() => handleNavigation('/')}
                >
                    {isActive('/') ? (
                        <HomeOutlined style={{ fontSize: '2.5em', color: '#ffc107' }} />
                    ) : (
                        <HomeOutlined style={{ fontSize: '2.5em', color: 'white' }} />
                    )}
                </Button>
            </div>
            <div>
                <Button
                    type="link"
                    className="text-dark menu-button"
                    onClick={() => handleNavigation('/profile')}
                >
                    {isActive('/profile') ? (
                        <UserOutlined style={{ fontSize: '2em', color: '#ffc107' }} />
                    ) : (
                        <UserOutlined style={{ fontSize: '2em', color: 'white' }} />
                    )}
                </Button>
            </div>
            {/* <div>
                <Button
                    type="link"
                    className="text-dark menu-button"
                    onClick={handleNextNavigation}
                >
                    <ArrowRightOutlined style={{ fontSize: '2em', color: '#FFF', marginLeft: 15 }} />
                </Button>
            </div> */}
        </div>
    );
};

export default BottomMobileMenu;
