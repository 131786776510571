import React from "react";
import SearchBar from "../components/search-bar/SearchBar";

const HomePage: React.FC = () => {
  return (
    <>
      <SearchBar />
    </>
  );
};

export default HomePage;
