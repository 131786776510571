import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MenuOutlined } from '@ant-design/icons';
import AdminCities from "./parts/AdminCities";
import AdminCategories from "./parts/AdminCategories";
import AdminHeader from "../../../components/admin-components/AdminHeader";
import AdminMenu from "../../../components/admin-components/AdminMenu";
import AdminUsers from './parts/AdminUsers';
import AdminDashboard from './parts/AdminDashboard';
import { Button, Drawer } from 'antd';

const AdminHomePage = () => {
    const [selectedMenu, setSelectedMenu] = useState<string>('1');
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleMenuSelect = (key: string) => {
        setSelectedMenu(key);
        if (isMobile) {
            setIsMenuVisible(false);
        }
    };

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    return (
        <>
            <AdminHeader />
            <div className="row">
                {isMobile ? (
                    <div className="col-12">
                        <Button
                            type="primary"
                            icon={<MenuOutlined />}
                            onClick={toggleMenu}
                            className="mb-3 d-flex m-2 p-4 bg-danger"
                        >
                        </Button>
                        <Drawer
                            title="Menu"
                            placement="left"
                            closable={true}
                            onClose={toggleMenu}
                            open={isMenuVisible}
                            width={250}
                        >
                            <AdminMenu onSelect={handleMenuSelect} />
                        </Drawer>
                    </div>
                ) : (
                    <div className="col-2">
                        <AdminMenu onSelect={handleMenuSelect} />
                    </div>
                )}

                <div className={isMobile ? "col-12 p-4" : "col-10 p-4"}>
                    {selectedMenu === '1' && <AdminDashboard />}
                    {selectedMenu === '2' && <AdminUsers />}
                    {selectedMenu === '3' && <AdminCategories />}
                    {selectedMenu === '4' && <AdminCities />}
                </div>
            </div>
        </>
    );
};

export default AdminHomePage;
