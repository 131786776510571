import React, { useState } from 'react';
import { Rate, Button, message, Modal } from 'antd';
import { memberService } from '../services/member.service';
import TextArea from 'antd/es/input/TextArea';
import { useAuth } from '../store/AuthContext';
import { useNavigate } from 'react-router-dom';

interface WorkerRatingProps {
  memberId: string;
  memberIsRated: string;
}

const WorkerRating: React.FC<WorkerRatingProps> = ({ memberId, memberIsRated }) => {
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value);
  };

  const handleSaveRating = async () => {
    if (!isAuthenticated) {
      setIsModalVisible(true);
      return;
    }

    if (rating === 0) {
      message.warning('Puan seçin!');
      return;
    }

    setLoading(true);
    try {
      await memberService.saveMemberRating(memberId, rating, review);
      message.success('Puan göndərildi!');
      window.location.reload();
    } catch (error: any) {
      const errorMessage = error.message || 'Paun vermisiniz artıq!';
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginRedirect = () => {
    setIsModalVisible(false);
    navigate('/login');
  };

  return (
    <div>
      {memberIsRated ? '' : (<>
        <h2>Puan Ver</h2>
        <Rate onChange={handleRatingChange} value={rating} />
        <div style={{ marginTop: '10px' }}>
          <TextArea
            placeholder="Rəy yazın (İstəyə uyğun)"
            value={review}
            onChange={handleReviewChange}
            style={{ width: '100%', minHeight: '0px', marginBottom: '10px' }}
          />
          <Button type="primary" className='bg-secondary' onClick={handleSaveRating} loading={loading}>
            Göndər
          </Button>
        </div>
      </>)}


      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button className='bg-warning col-12 col-md-4' key="login" type="primary" onClick={handleLoginRedirect}>
            Giriş et
          </Button>,
        ]}
      >
        Puan vermək üçün giriş edin!
      </Modal>
    </div>
  );
};

export default WorkerRating;
