import { Category } from "../types/category.type";
import axios from "./axios/_axios";

export const categoryService = {
    getAllCategories: async () => {
        return await axios.get(`/api/categories`);
    },
    createCategory: async (category: Category) => {
        return await axios.post(`/api/categories`, category);
    },
    updateCategory: async (id: number, category: Category) => {
        return await axios.put(`/api/categories/${id}`, category);
    },
    deleteCategory: async (id: number) => {
        return await axios.delete(`/api/categories/${id}`);
    },
};
