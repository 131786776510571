import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { memberService } from "../services/member.service";
import { Card, Spin, Descriptions, Row, Typography, Image, Button, Col, Upload, message, Modal, Divider, Drawer, Input, Form } from "antd";
import { baseURL } from "../services/axios/_axios";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import BeMasterDrawer from "../components/BeMasterDrawer";

const { Title } = Typography;

const ProfilePage = () => {
  const { id } = useParams<{ id: string }>();
  const [member, setMember] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isMaster, setIsMaster] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await memberService.getMemberDetails();
        const memberData = response.data.data;
        setMember(memberData);
        console.log('memberData', memberData);
        setIsMaster(response.data.data.isMaster);
        setAttachments(memberData.workImageUrls);
      } catch (error) {
        console.error("Failed to fetch member data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [id]);

  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const age = useMemo(() => calculateAge(member?.birthDate), [member?.birthDate]);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("Attachments", file);

    try {
      setUploading(true);
      await memberService.uploadMemberAttachment(member.id, formData);
      message.success("Şəkil uğurla yükləndi");
      const response = await memberService.getMemberDetails();
      setAttachments(response.data.data.workImageUrls);
    } catch (error) {
      console.error("Failed to upload image:", error);
      message.error("Şəkili yükləmək mümkün olmadı");
    } finally {
      setUploading(false);
    }
  };


  const handleDelete = (attachmentUrl: string) => {
    const segments = attachmentUrl.split('/');
    const attachmentId = segments[segments.length - 2];

    if (!attachmentId) {
      message.error("Səhv attachment ID");
      return;
    }

    Modal.confirm({
      content: 'Şəkli silməyə əminsizimi?',
      okText: 'Bəli',
      cancelText: 'Xeyr',
      onOk: async () => {
        try {
          console.log("Silinecek attachment ID:", attachmentId);

          await memberService.deleteMemberAttachment(member.id, attachmentId);
          message.success("Şəkil silindi");
          setAttachments(attachments.filter(url => url !== attachmentUrl));
        } catch (error) {
          console.error("Resmi silerken hata oluştu:", error);
          message.error("Resmi silmek mümkün olmadı");
        }
      },
      onCancel() {
        console.log('Silme iptal edildi');
      }
    });
  };

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  }

  if (!member) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Title level={3}></Title>
      </Row>
    );
  }

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleResetPassword = async () => {
    try {
      await memberService.resetPassword(currentPassword, newPassword, confirmPassword);
      message.success("Şifrə uğurla yeniləndi");
      toggleDrawer();
    } catch (error: any) {
      if (error.response) {
        const errorData = error.response.data;

        if (error.response.status === 400) {
          if (Array.isArray(errorData) && errorData.length > 0) {
            message.error(errorData[0], 1);
          } else if (typeof errorData.message === 'string') {
            message.error(errorData.message, 1);
          } else {
            message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
          }
        } else {
          message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
        }
      } else {
        message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
      }
    }
  };

  const profileImageUrl = member.profileImageUrl
    ? `${baseURL}${member.profileImageUrl}`
    : "/images/avatar.jpg";

  return (
    <div className="d-flex flex-column align-items-center">
      <Card className="p-2" style={{ width: "100%", maxWidth: "600px" }}>
        <div className="d-flex align-items-center">
          <div>
            {profileImageUrl && (
              <Image
                src={profileImageUrl}
                className="col-6 col-md-3 p-1"
                alt="Profile"
                style={{
                  maxHeight: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  width: "80px",
                  minHeight: "80px",
                  minWidth: "80px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginBottom: "20px",
                  cursor: "pointer",
                  border: "1px solid #F1F1F1",
                }}
              />
            )}
          </div>
          <div className="m-3">
            <Title level={5} style={{ textAlign: "center", marginBottom: "20px" }}>
              {`${member.firstName} ${member.lastName} ${member.patronmicName ?? ""}`}
            </Title>
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-end">
          <Button
            type="primary"
            className="bg-secondary mb-1"
            onClick={toggleDrawer}
            style={{ height: '35px', borderRadius: '12px', fontSize: 'small' }}
          >
            Şifrəni yenilə
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(isMaster ? "/master-profile-edit" : "/profile-edit")}
            className="bg-warning mb-1"
            style={{ height: '35px', borderRadius: '12px', fontSize: 'small', marginLeft: 10 }}
          >
            Redaktə
          </Button>
        </div>
        <Drawer
          title="Şifrəni yenilə"
          placement="bottom"
          onClose={toggleDrawer}
          open={isDrawerOpen}
          size="large"
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form className="col-12 col-md-4">
              <Form.Item label="Cari Şifrə" name="CurrentPassword">
                <Input.Password
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
              </Form.Item>
              <Form.Item label="Yeni Şifrə" name="NewPassword" rules={[
                { required: true, message: "Yeni şifrə daxil edin!" }]} >
                <Input.Password
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
              </Form.Item>
              <Form.Item
                name="ConfirmPassword"
                label="Təkrar şifrə"
                dependencies={['NewPassword']}
                hasFeedback
                rules={[
                  { required: true, message: "Şifrənizi təkrar daxil edin!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const NewPasswordValue = getFieldValue('NewPassword');
                      if (!value || NewPasswordValue === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Şifrələr uyğun deyil!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
              </Form.Item>
            </Form>
          </div>
          <Divider />
          <div className="d-flex justify-content-center">
            <Button
              className="bg-warning text-light col-12 col-md-3"
              type="primary"
              style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
              onClick={handleResetPassword}
            >
              Hazırdır
            </Button>
          </div>
        </Drawer>
        <Descriptions bordered column={1} size="small">
          {isMaster && (
            <>
              <Descriptions.Item label="Əsas iş">{member.category?.name ?? ""}</Descriptions.Item>
              <Descriptions.Item label="Digər bacarıqlar">
                {member.categories && member.categories.length > 0
                  ? member.categories.map((cat: any) => cat.name).join(", ")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Şəhərlər">
                {member.cities && member.cities.length > 0
                  ? member.cities.map((city: any) => city.name).join(", ")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Yaş">
                {age}
              </Descriptions.Item>
              <Descriptions.Item label="Cinsiyyət">
                {member.gender === 1 ? "Kişi" : member.gender === 2 ? "Qadın" : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Təcrübə">
                {member.experience === 1 ? "1 ildən aşağı" : member.experience === 2 ? "1 ildən 3 ilə qədər" : member.experience === 3 ? "3 ildən 5 ilə qədər" : member.experience === 4 ? "5 ildən artıq" : ""}
              </Descriptions.Item>
            </>
          )}
          <Descriptions.Item label="Telefon nömrəsi">{member.phoneNumber}</Descriptions.Item>
        </Descriptions>
        <Divider className="text-secondary">Haqqımda</Divider>
        {member.description ? (<p className="mt-2" style={{ borderRadius: '12px', fontSize: 'medium', resize: 'none', textAlign: 'justify' }} >{member.description ?? ""}</p>) : ''}
      </Card>

      {isMaster && (
        <div className="mt-1 p-2">
          <Divider className="text-secondary">Şəkillər</Divider>
          <Image.PreviewGroup>
            <Row gutter={[10, 10]} className="mb-3">
              {attachments.length > 0 ? (
                attachments.map((url: string, index: number) => (
                  <Col key={index} xs={12} sm={8} md={6} lg={6}>
                    <div className="p-1" style={{ position: "relative", height: "100px", minHeight: '120px', maxHeight: '120px', borderRadius: "8px", overflow: "hidden" }}>
                      <Image
                        src={`${baseURL}${url}`}
                        alt={`Attachment ${index}`}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                          cursor: "pointer",
                          objectPosition: "center"
                        }}
                        preview={true}
                      />
                      <Button
                        type="primary"
                        onClick={() => handleDelete(url)}
                        style={{ position: "absolute", top: 0, right: 0, backgroundColor: '#C7253E' }}
                        icon={<DeleteOutlined />}
                      >
                      </Button>
                    </div>
                  </Col>
                ))
              ) : (
                " "
              )}
            </Row>
          </Image.PreviewGroup>

          <Upload
            customRequest={({ file }) => handleUpload(file)}
            showUploadList={false}
            accept="image/*"
          >
            <Button icon={<PlusOutlined />} loading={uploading} style={{ height: '35px', borderRadius: '12px', fontSize: 'medium' }}>
              Yeni şəkil əlavə et
            </Button>
          </Upload>
        </div>
      )}

      {!isMaster && (
        <BeMasterDrawer />
      )}
    </div>
  );
};

export default ProfilePage;
