import { useCallback, useEffect, useState } from 'react';
import { Drawer, Form, Button, Select, notification, Radio, DatePicker } from 'antd';
import { Category, FormattedCategory } from '../types/category.type';
import { City, FormattedCity } from '../types/city.type';
import { categoryService } from '../services/category.service';
import { cityService } from '../services/city.service';
import { memberService } from '../services/member.service';
import TextArea from 'antd/es/input/TextArea';

const { Option } = Select;

const BeMasterDrawer = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<FormattedCategory[]>([]);
  const [cities, setCities] = useState<FormattedCity[]>([]);
  const [memberId, setMemberId] = useState<string | null>(null);

  const formatCategoryTree = useCallback((data: Category[], indentLevel: number = 0): FormattedCategory[] => {
    return data.flatMap((category) => {
      const formattedCategory: FormattedCategory = {
        id: category.id,
        name: category.name,
        isParent: !!category.children && category.children.length > 0,
        indent: indentLevel
      };

      const childrenCategories = category.children ? formatCategoryTree(category.children, indentLevel + 1) : [];
      return [formattedCategory, ...childrenCategories];
    });
  }, []);

  const formatCityTree = useCallback((data: City[], indentLevel: number = 0): FormattedCity[] => {
    return data.flatMap((city) => {
      const formattedCity: FormattedCity = {
        id: city.id,
        name: city.name,
        isParent: !!city.children && city.children.length > 0,
        indent: indentLevel
      };

      const childrenCities = city.children ? formatCityTree(city.children, indentLevel + 1) : [];
      return [formattedCity, ...childrenCities];
    });
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await categoryService.getAllCategories();
      setCategories(formatCategoryTree(response.data.data));
    };

    const fetchCities = async () => {
      const response = await cityService.getAllCities();
      setCities(formatCityTree(response.data.data));
    };

    const fetchMemberId = async () => {
      try {
        const response = await memberService.getMemberDetails();
        setMemberId(response.data.data.id);
      } catch (error) {
        console.error('Error fetching member ID:', error);
      }
    };

    fetchCategories();
    fetchCities();
    fetchMemberId();
  }, [formatCategoryTree, formatCityTree]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleUpgrade = async () => {
    try {
      if (!memberId) {
        notification.error({ message: 'Member ID is not available.' });
        return;
      }

      const values = form.getFieldsValue();

      const data = {
        categoryId: values.category,
        additionalCategories: values.categories ? values.categories : [],
        cities: values.cities || [],
        birthDate: values.birthDate ? values.birthDate.format("MM/DD/YYYY") : null,
        gender: values.gender,
        experience: values.experience,
        description: values.description,
      };

      await memberService.upgradeToMaster(memberId, data);
      notification.success({ message: 'Profil uğurla yeniləndi!' });

      window.location.reload();
      onClose();
    } catch (error) {
      console.error('Error upgrading to master:', error);
    }
  };


  return (
    <>
      <Button onClick={showDrawer} type='primary' className='bg-secondary col-6 col-md-2 mt-3' style={{ height: '40px', borderRadius: '12px', fontSize: 'medium' }}>Usta ol</Button>
      <Drawer
        open={open}
        onClose={onClose}
        size="large"
        footer={
          <div className='mb-2 mt-2' style={{ textAlign: 'right' }}>
            <Button className='col-4' onClick={onClose} style={{ height: '40px', borderRadius: '12px', fontSize: 'medium', marginRight: 8 }}>
              Ləğv et
            </Button>
            <Button type="primary" className='bg-warning col-4' style={{ height: '40px', borderRadius: '12px', fontSize: 'medium' }} onClick={handleUpgrade}>
              Usta ol
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Əsas iş" name="category" rules={[{ required: true, message: "" }]}>
            <Select style={{ borderRadius: '12px', fontSize: 'medium', height: '45px', }}>
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.id} style={{ paddingLeft: category.isParent ? 0 : 20, fontWeight: category.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Digər işlər" name="categories">
            <Select
              mode="multiple"
              style={{ borderRadius: '12px', fontSize: 'medium', height: '45px' }}
            >
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.id} style={{ paddingLeft: category.isParent ? 0 : 20, fontWeight: category.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Şəhərlər" name="cities" rules={[{ required: true, message: "Şəhər seçilməlidir!" }]}>
            <Select mode="multiple" style={{ borderRadius: '12px', fontSize: 'medium', height: '45px' }}>
              {cities.map((city) => (
                <Select.Option key={city.id} value={city.id} style={{ paddingLeft: city.isParent ? 0 : 20, fontWeight: city.isParent ? 'bold' : 'normal', fontSize: 'medium' }}>
                  {city.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Birth Date */}
          <Form.Item
            name="birthDate"
            rules={[{ required: true, message: "Doğum tarixinizi daxil edin!" }]}
          >
            <DatePicker
              placeholder="Doğum tarixi"
              format="DD.MM.YYYY"
              style={{ width: "100%", height: '45px', borderRadius: '12px' }}
            />
          </Form.Item>

          {/* Gender */}
          <Form.Item
            name="gender"
            rules={[{ required: true, message: "Cinsiyyətinizi seçin!" }]}
          >
            <Radio.Group optionType="button" buttonStyle="solid" className="col-12 custom-radio-group">
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '12px 0 0 12px', textAlign: 'center', paddingTop: '2px' }} value={1}>Kişi</Radio.Button>
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '0 12px 12px 0', textAlign: 'center', paddingTop: '2px' }} value={2}>Qadın</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* Experience */}
          <Form.Item
            name="experience"
            rules={[{ required: true, message: "Təcrübənizi seçin!" }]}
          >
            <Select placeholder="Təcrübənizi seçin" style={{ width: "100%", height: '45px' }}>
              <Option value={1}>1 ildən aşağı</Option>
              <Option value={2}>1 ildən 3 ilə qədər</Option>
              <Option value={3}>3 ildən 5 ilə qədər</Option>
              <Option value={4}>5 ildən artıq</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
          >
            <TextArea placeholder="Qeyd" rows={3} style={{ borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default BeMasterDrawer;
