import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { memberService } from "../services/member.service";
import { Card, Spin, Descriptions, Row, Col, Typography, Image, Modal, Button, Rate, Input, Divider } from "antd";
import { baseURL } from "../services/axios/_axios";
import WorkerRating from "../components/WorkerRating";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const { Title } = Typography;

const EmployeeDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const [member, setMember] = useState<any>(null);
  const [ratings, setRatings] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const totalRatings = ratings.length;

  const age = useMemo(() => calculateAge(member?.birthDate), [member?.birthDate]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCall = () => {
    window.location.href = `tel:${member.phoneNumber}`;
  };

  useEffect(() => {
    const fetchMemberData = async () => {
      setLoading(true);
      try {
        const response = await memberService.getMemberById(id!);
        setMember(response.data.data);

        const ratingsResponse = await memberService.getMemberRatings(id!);
        setRatings(ratingsResponse.data.data);
      } catch (error) {
        console.error("Failed to fetch member data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [id]);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Row>
    );
  }

  if (!member) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Title level={3}></Title>
      </Row>
    );
  }

  const profileImageUrl = member.profileImageUrl
    ? `${baseURL}${member.profileImageUrl}`
    : "/images/avatar.jpg";

  return (
    <>
      <div className="d-flex flex-column align-items-center mt-3 col-12">
        <Card className="p-2" style={{ width: "100%"}}>
          <div className="d-flex align-items-center">
            <div>
              {profileImageUrl && (
                <Image
                  src={profileImageUrl}
                  className="col-6 col-md-3 p-1"
                  alt="Profile"
                  style={{
                    maxHeight: "80px",
                    maxWidth: "80px",
                    height: "80px",
                    width: "80px",
                    minHeight: "80px",
                    minWidth: "80px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    marginBottom: "20px",
                    cursor: "pointer",
                    border: "1px solid #F1F1F1",
                  }}
                />
              )}
            </div>
            <div>
              <div style={{ marginLeft: 15 }}>
                <Title
                  level={5}
                  style={{ textAlign: "start", justifyContent: 'start' }}
                >
                  {`${member.firstName} ${member.lastName} ${member.patronmicName ?? ""}`}
                </Title>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}>
                <h4 style={{ color: 'rgb(93 93 94)' }}>
                  {Number.isInteger(member.averageRating)
                    ? member.averageRating
                    : (Math.round(member.averageRating * 10) / 10).toFixed(1)}
                </h4>
                <Rate disabled value={member.averageRating} style={{ fontSize: '15px', marginLeft: 10, marginBottom: 5, }} />
                <h6 style={{ marginLeft: 10, color: 'rgb(93 93 94)' }}>({totalRatings})</h6>
              </div>
            </div>
          </div>
          <Descriptions bordered column={1} size="middle">
            <Descriptions.Item label="Əsas iş">
              {member.category?.name ?? ""}
            </Descriptions.Item>
            <Descriptions.Item label="Digər bacarıqlar">
              {member.categories && member.categories.length > 0
                ? member.categories.map((cat: any) => cat.name).join(", ")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Şəhərlər">
              {member.cities && member.cities.length > 0
                ? member.cities.map((city: any) => city.name).join(", ")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Telefon nömrəsi">
              <Button type="dashed" style={{ backgroundColor: 'rgb(245 236 177)' }} onClick={showModal}><FontAwesomeIcon icon={faPhone} key="phone" />Nömrə</Button>
            </Descriptions.Item>
            <Descriptions.Item label="Cinsiyyət">
              {member.gender === 1 ? "Kişi" : member.gender === 2 ? "Qadın" : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Yaş">
              {age}
            </Descriptions.Item>
            <Descriptions.Item label="Təcrübə">
              {member.experience === 1 ? "1 ildən aşağı" : member.experience === 2 ? "1 ildən 3 ilə qədər" : member.experience === 3 ? "3 ildən 5 ilə qədər" : member.experience === 4 ? "5 ildən artıq" : ""}
            </Descriptions.Item>
          </Descriptions>

          
          {member.description ? (<Divider className="text-secondary">Haqqımda</Divider>) : ''}
          {member.description ? (<p className="mt-2" style={{ borderRadius: '12px', fontSize: 'medium', resize: 'none', textAlign: 'justify' }} >{member.description ?? ""}</p>) : ''}

        </Card>

        <div className="mt-1 p-2">
          {member.workImageUrls.length > 0 ? (
            <Divider className="text-secondary">Şəkillər</Divider>
          )
            : ''
          }
          <Image.PreviewGroup>
            <Row gutter={[10, 10]}>
              {member.workImageUrls && member.workImageUrls.length > 0 ? (
                member.workImageUrls.map((url: string, index: number) => (
                  <Col key={index} xs={12} sm={8} md={6} lg={6}>
                    <div style={{ position: "relative", height: "100px", minHeight: '120px', maxHeight: '120px', borderRadius: "8px", overflow: "hidden" }}>
                      <Image
                        src={`${baseURL}${url}`}
                        alt={`Attachment ${index}`}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                          cursor: "pointer",
                          objectPosition: "center"
                        }}
                        preview={true}
                      />
                    </div>
                  </Col>
                ))
              ) : (
                ' '
              )}
            </Row>
          </Image.PreviewGroup>
        </div>

        <div>
          <WorkerRating memberId={member.id} memberIsRated={member.isRated} />
        </div>
        <div className="mt-3 p-2" style={{ width: '100%' }}>
          {ratings.length > 0 && <Divider className="text-secondary">Rəylər</Divider>}
          {ratings.map((rating, index) => (
            <Card key={index} style={{ margin: "10px 0", borderRadius: '8px', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                <span style={{ fontWeight: 'bold', color: 'rgb(69, 71, 75)' }}>{rating.ratedByMemberFullName}</span>
                <Rate disabled value={rating.ratingValue} style={{ fontSize: '10px' }} />
              </div>
              <Input
                style={{ border: '0px', marginTop: '8px' }}
                value={rating.reviewText}
                readOnly
              />
            </Card>
          ))}
        </div>
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          centered
        >
          <Button type='dashed' className="col-12 bg-light mt-5" style={{ height: '50px', fontSize: '20px', fontWeight: '600' }} onClick={handleCall}>
            {member.phoneNumber}
          </Button>
        </Modal>
      </div>
    </>
  );
};

export default EmployeeDetailPage;
