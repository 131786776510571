import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MemberCard from "./MemberCard";
import Cookies from "js-cookie";
import { message, Pagination, Spin } from "antd";
import { memberService } from "../../services/member.service";

interface EmployeeCardProps {
  selectedCategories: string[];
  selectedCities: string[];
  filterValues: any;
  triggerSearch: boolean;
  setTriggerSearch: (value: boolean) => void;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({
  selectedCategories,
  selectedCities,
  filterValues,
  triggerSearch,
  setTriggerSearch,
}) => {
  const [members, setMembers] = useState<any[]>([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [favorites, setFavorites] = useState<any[]>([]);
  const pageSize = 12;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        const response = await memberService.searchMasters(currentPage, pageSize, {
          categoryIds: selectedCategories.length > 0 ? selectedCategories : undefined,
          cityIds: selectedCities.length > 0 ? selectedCities : undefined,
          ...filterValues,
        });

        const items = response.data.data.items;
        setTotalMembers(response.data.data.totalCount);
        setMembers(items);
      } catch (error) {
        console.error('Failed to fetch members:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = () => {
      const favoritesFromCookies = Cookies.get('favorites') ? JSON.parse(Cookies.get('favorites')!) : [];
      setFavorites(favoritesFromCookies);
    };

    if (triggerSearch) {
      fetchMembers();
      fetchFavorites();
      setTriggerSearch(false);
    }
  }, [currentPage, triggerSearch, selectedCategories, selectedCities, filterValues, setTriggerSearch]);

  useEffect(() => {
    setTriggerSearch(true);
  }, [currentPage, setTriggerSearch]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFavoriteToggle = (member: any) => {
    const isAlreadyFavorite = favorites.some((fav: any) => fav.id === member.id);

    if (isAlreadyFavorite) {
      const updatedFavorites = favorites.filter((fav: any) => fav.id !== member.id);
      Cookies.set('favorites', JSON.stringify(updatedFavorites));
      setFavorites(updatedFavorites);
      message.error('Favorilərdən çıxarıldı', 1);
    } else {
      const memberData = {
        id: member.id,
        firstName: member.firstName,
        lastName: member.lastName,
        profileImageUrl: member.profileImageUrl,
        category: member.category,
        cities: member.cities,
        phoneNumber: member.phoneNumber,
      };
      const updatedFavorites = [...favorites, memberData];
      Cookies.set('favorites', JSON.stringify(updatedFavorites));
      setFavorites(updatedFavorites);
      message.success('Favorilərə əlavə edildi', 1);
    }
  };

  const isFavorite = (memberId: number) => {
    return favorites.some((fav: any) => fav.id === memberId);
  };

  return (
    <>
      <div className={`employee-card-container ${isMobile ? 'mobile' : 'desktop'}`}>
        {loading ? (
          <div className="loader-container">
            <Spin size="large" />
          </div>
        ) : (
          members.length > 0 ? (
            members.map(member => (
              <MemberCard
                key={member.id}
                member={member}
                isFavorite={isFavorite(member.id)}
                onFavoriteToggle={handleFavoriteToggle}
              />
            ))
          ) : (
            <div>
              <p>İstifadəçi tapılmadı</p>
            </div>
          )
        )}
      </div>
      {totalMembers > pageSize && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalMembers}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default EmployeeCard;
