import React, { useState } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../store/AuthContext";
import { memberService } from "../services/member.service";

const { Option } = Select;

const LoginPage: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const formatPhoneNumberDisplay = (phoneNumber: string) => {
    const digits = phoneNumber.replace(/[^\d]/g, '');
    const formatted = `+${digits.slice(0, 3)} ${digits.slice(3, 5)} ${digits.slice(5, 8)} ${digits.slice(8, 10)} ${digits.slice(10, 12)}`;
    return formatted.trim();
  };

  const onFinish = async (values: { prefix: string; phoneNumber: string; password: string }) => {
    const { prefix, phoneNumber, password } = values;
    const formattedPhoneNumber = `+994 ${prefix} ${phoneNumber.replace(/\s/g, '').slice(0, 3)} ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8, 10)}`.trim();

    try {
      const response = await memberService.login(formattedPhoneNumber, password);
      if (response.status === 200) {
        message.success("Uğurla giriş edildi!", 1);
        localStorage.setItem("access-token", response.data.data.token);
        localStorage.setItem("refresh-token", response.data.data.refreshToken);
        login();
        navigate("/");
      } else {
        message.error("Giriş Edilmədi! Nömrə vəya Password yalnışdır.", 1);
      }
    } catch (error) {
      message.error("Giriş Edilmədi! Nömrə vəya Password yalnışdır.", 1);
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ height: '45px', width: 70 }}>
        <Option value="50">050</Option>
        <Option value="51">051</Option>
        <Option value="55">055</Option>
        <Option value="70">070</Option>
        <Option value="77">077</Option>
        <Option value="10">010</Option>
        <Option value="99">099</Option>
        <Option value="60">060</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="mt-4" style={{ maxWidth: 400, margin: "0 auto", padding: "20px" }}>
      <Form
        name="login_form"
        initialValues={{ remember: true, prefix: "50" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: "Telefon nömrənizi daxil edin!" }]}
        >
          <Input
            type="tel"
            addonBefore={prefixSelector}
            style={{ height: '45px', fontSize: 'medium' }}
            onChange={(e) => setPhoneNumber(formatPhoneNumberDisplay(e.target.value))}
            value={phoneNumber}
            placeholder="Telefon nömrəsi"
            maxLength={7}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Şifrənizi daxil edin!" }]}
        >
          <Input.Password placeholder="Şifrə" style={{ height: '45px', fontSize: 'medium', borderRadius: '12px' }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" className='bg-warning' htmlType="submit" style={{ width: "100%", height: '45px', borderRadius: '12px' }}>
            Giriş
          </Button>
        </Form.Item>
      </Form>
      <div style={{ marginTop: 20, textAlign: "center" }}>
        Hesabınız yoxdurmu? <Link to="/register">Qeydiyyatdan Keç</Link>
      </div>
    </div>
  );
};

export default LoginPage;
