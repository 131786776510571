import React from 'react';
import { Button, Drawer, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined, SettingOutlined } from "@ant-design/icons";
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../../store/AuthContext';


const { Header } = Layout;

const HeaderComponent: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { isAuthenticated, logout } = useAuth();

    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    const handleNavigation = (path: string) => {
        navigate(path);
        setOpen(false)
    };

    const handleLogout = () => {
        logout();
        setOpen(false);
        navigate('/');
    };

    const showLoading = () => {
        setOpen(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    return (
        <Header className='gap-2 d-flex align-items-center justify-content-between p-3' style={{ backgroundColor: '#45474B', position: 'sticky', top: 0, zIndex: '999' }} >
            <div>
                <Button onClick={() => handleNavigation('/')} icon={<HomeOutlined />}>
                    {!isMobile && <span style={{ marginLeft: 8 }}>Ana səhifə</span>}
                </Button>
            </div>
            <div className='col-4 col-sm-5 col-md-6 d-flex justify-content-start'>
                <img onClick={() => handleNavigation('/')} className='col-12 col-md-3' src={process.env.PUBLIC_URL + "/images/logo_yellow.png"} alt="Your Company" style={{ cursor: 'pointer' }} />
            </div>
            {isMobile ? (
                <>
                    <Button onClick={showLoading} icon={<SettingOutlined />} />
                    <Drawer
                        closable
                        destroyOnClose
                        placement="right"
                        open={open}
                        loading={loading}
                        size='large'
                        onClose={() => setOpen(false)}
                    >
                        <div className='d-flex flex-column'>
                            {isAuthenticated ? (
                                <>
                                    <Button type="primary" className='bg-secondary mb-3' onClick={() => handleNavigation('/profile')} style={{ height: '45px', fontSize: '17px', fontWeight: '600', borderRadius: '12px' }}>
                                        Profil
                                    </Button>
                                    <Button type="primary" className='bg-danger mb-3' onClick={handleLogout} style={{ height: '45px', fontSize: '17px', fontWeight: '600', borderRadius: '12px' }}>
                                        Çıxış
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button type="primary" className='bg-secondary mb-3' onClick={() => handleNavigation('/login')} style={{ height: '40px', fontSize: '15px', borderRadius: '20px' }}>
                                        Giriş et
                                    </Button>
                                    <Button type="primary" className='bg-secondary mb-3' onClick={() => handleNavigation('/register')} style={{ height: '40px', fontSize: '15px', borderRadius: '20px' }}>
                                        Qeydiyyatdan keç
                                    </Button>
                                    <Button type="primary" className='bg-warning mb-3' onClick={() => handleNavigation('/worker-register')} style={{ height: '40px', fontSize: '15px', borderRadius: '20px' }}>
                                        Usta kimi qeydiyyatdan keç
                                    </Button>
                                </>
                            )}
                        </div>
                    </Drawer>
                </>
            ) : (
                <div className='d-flex align-items-center'>
                    {isAuthenticated ? (
                        <>
                            <Button type="primary" className='bg-danger m-' onClick={() => handleNavigation('/favorites')}>
                                Favoritlər
                            </Button>
                            <Button type="default" className='m-2' onClick={() => handleNavigation('/profile')}>
                                Profil
                            </Button>
                            <Button type="default" className='m-2' onClick={handleLogout}>
                                Çıxış
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button type="primary" className='bg-danger m-' onClick={() => handleNavigation('/favorites')}>
                                Favoritlər
                            </Button>
                            <Button type="default" className='m-2' onClick={() => handleNavigation('/login')}>
                                Giriş et
                            </Button>
                            <Button type="default" className='m-2' onClick={() => handleNavigation('/register')}>
                                Qeydiyyat
                            </Button>
                            <Button type="primary" className='bg-warning m-2' onClick={() => handleNavigation('/worker-register')}>
                                Usta kimi qeydiyyatdan keç
                            </Button>
                        </>
                    )}
                </div>
            )}
        </Header>
    );
}

export default HeaderComponent;
