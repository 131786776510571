import React, { useState } from 'react';
import {
    HomeOutlined,
    MinusOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        key: '1',
        icon: <HomeOutlined />,
        label: 'Ana səhifə',
    },
    {
        key: '2',
        icon: <UserOutlined />,
        label: 'İstifadəçilər',
    },
    {
        key: '3',
        icon: <MinusOutlined />,
        label: 'İşlər',
    },
    {
        key: '4',
        icon: <MinusOutlined />,
        label: 'Şəhərlər',
    },
];

interface AdminMenuProps {
    onSelect: (key: string) => void;
}

const AdminMenu: React.FC<AdminMenuProps> = ({ onSelect }) => {
    const [selectedKey, setSelectedKey] = useState<string>('1');

    const handleClick = (e: { key: string }) => {
        setSelectedKey(e.key);
        onSelect(e.key);
    };

    return (
        <>
            <Menu
                style={{ width: 256 }}
                selectedKeys={[selectedKey]}
                items={items}
                onClick={handleClick}
                className='admin-menu'
            />
        </>
    );
};

export default AdminMenu;
