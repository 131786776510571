import React, { useEffect, useState } from 'react';
import { List, Input, Button, Typography, Checkbox, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { City } from '../../types/city.type';
import { cityService } from '../../services/city.service';
import { Drawer } from 'antd/lib';
import Cookies from 'js-cookie';

const { Text } = Typography;
const { Option } = Select;

interface CitiesProps {
    onChange?: (selectedRegisterCities: string[]) => void;
}

const RegisterCities: React.FC<CitiesProps> = ({ onChange = () => { } }) => {
    const [cities, setCities] = useState<City[]>([]);
    const [selectedRegisterCities, setselectedRegisterCities] = useState<string[]>(() => {
        const cookieData = Cookies.get('selectedRegisterCities');
        return cookieData ? JSON.parse(cookieData) : [];
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        const getCities = async () => {
            try {
                const response = await cityService.getAllCities();
                setCities(response.data.data);
            } catch (error) {
            }
        };

        getCities();
    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen((prev) => !prev);
    };

    const transformCities = (cities: City[]): { label: JSX.Element; value: string; disabled?: boolean }[] => {
        const transformedOptions: { label: JSX.Element; value: string; disabled?: boolean }[] = [];

        const addCityWithChildren = (city: City, level: number) => {
            const isParent = city.children.length > 0;
            transformedOptions.push({
                label: (
                    <Text style={{ marginLeft: level * 20, fontWeight: isParent ? '600' : '400' }}>
                        {city.name}
                    </Text>
                ),
                value: city.id,
                disabled: false,
            });
            city.children.forEach(child => addCityWithChildren(child, level + 1));
        };

        cities.forEach(city => addCityWithChildren(city, 0));
        return transformedOptions;
    };

    const options = transformCities(cities);

    useEffect(() => {
        const allCityIds = cities.flatMap(city => [city.id, ...getAllDescendantIds(city)]);
        if (selectedRegisterCities.length === allCityIds.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedRegisterCities, cities]);

    const getAllDescendantIds = (city: City): string[] => {
        const descendants: string[] = [];
        const addDescendants = (c: City) => {
            c.children.forEach(child => {
                descendants.push(child.id);
                addDescendants(child);
            });
        };
        addDescendants(city);
        return descendants;
    };

    const handleSelectAllChange = (e: any) => {
        if (e.target.checked) {
            const allCityIds = cities.flatMap(city => [city.id, ...getAllDescendantIds(city)]);
            setselectedRegisterCities(allCityIds);
        } else {
            setselectedRegisterCities([]);
        }
        setSelectAll(e.target.checked);
    };

    const handleCitySelection = (cityId: string) => {
        setselectedRegisterCities(prevSelected => {
            let newSelected = [...prevSelected];

            const toggleSelection = (id: string, select: boolean) => {
                if (select) {
                    if (!newSelected.includes(id)) newSelected.push(id);
                } else {
                    newSelected = newSelected.filter(selectedId => selectedId !== id);
                }
            };

            const city = findCityById(cities, cityId);
            if (city) {
                const descendantIds = getAllDescendantIds(city);
                const select = !newSelected.includes(cityId);
                toggleSelection(cityId, select);
                descendantIds.forEach(descendantId => toggleSelection(descendantId, select));
            }

            updateParentSelection(newSelected, cityId);

            return newSelected;
        });
    };

    const updateParentSelection = (selectedIds: string[], cityId: string) => {
        const parentCity = findParentCity(cities, cityId);
        if (parentCity) {
            const allChildrenSelected = parentCity.children.every(child => selectedIds.includes(child.id));
            if (allChildrenSelected) {
                if (!selectedIds.includes(parentCity.id)) {
                    selectedIds.push(parentCity.id);
                }
            } else {
                selectedIds = selectedIds.filter(id => id !== parentCity.id);
            }
            updateParentSelection(selectedIds, parentCity.id);
        }
    };

    const findCityById = (cities: City[], id: string): City | undefined => {
        for (const city of cities) {
            if (city.id === id) return city;
            const childCity = findCityById(city.children, id);
            if (childCity) return childCity;
        }
        return undefined;
    };

    const findParentCity = (cities: City[], id: string): City | undefined => {
        for (const city of cities) {
            if (city.children.some(child => child.id === id)) {
                return city;
            }
            const parentCity = findParentCity(city.children, id);
            if (parentCity) return parentCity;
        }
        return undefined;
    };

    const handleClearSelection = () => {
        setselectedRegisterCities([]);
        setSelectAll(false);
    };

    const handleOk = () => {
        setIsDrawerOpen(false);
    };

    const getSelectedCityNames = () => {
        const findCityNameById = (cities: City[], id: string): string | undefined => {
            for (const city of cities) {
                if (city.id === id) return city.name;
                const childCityName = findCityNameById(city.children, id);
                if (childCityName) return childCityName;
            }
            return undefined;
        };

        return selectedRegisterCities.map(id => findCityNameById(cities, id)).filter(name => name !== undefined).join(', ');
    };

    useEffect(() => {
        Cookies.set("selectedRegisterCities", JSON.stringify(selectedRegisterCities), { expires: 7 });
        onChange(selectedRegisterCities);
    }, [selectedRegisterCities, onChange]);

    return (
        <div>
            {isMobile ? (
                <Input
                    readOnly
                    className='input-focus-remove'
                    placeholder='Şəhər seçin...'
                    value={getSelectedCityNames()}
                    onClick={() => setIsDrawerOpen(true)}
                    suffix={selectedRegisterCities.length > 0 ? <Button onClick={handleClearSelection}>x</Button> : null}
                    style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
                />
            ) : (
                <Select
                    mode="multiple"
                    placeholder="Şəhər seçin..."
                    className='input-focus-remove text-start'
                    value={selectedRegisterCities}
                    onChange={setselectedRegisterCities}
                    style={{ width: '100%', height: '45px', borderRadius: '12px' }}
                    maxTagCount={2}
                >
                    {options.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            )}

            {isMobile && (
                <Drawer
                    placement="bottom"
                    size='large'
                    onClose={toggleDrawer}
                    open={isDrawerOpen}
                    style={{ borderRadius: '20px 20px 0 0' }}
                >
                    <div style={{ paddingBottom: '50px' }}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <List.Item
                                onClick={() => handleSelectAllChange({ target: { checked: !selectAll } })}
                                className='rounded col-12 col-md-3 m-md-2'
                                style={{
                                    backgroundColor: selectAll ? '#e0e0e0' : '#F4F4F4', cursor: 'pointer', display: 'flex',
                                    justifyContent: 'space-between', alignItems: 'center', padding: '15px'
                                }}
                            >
                                <Text style={{ fontWeight: '600' }}>Hamısı</Text>
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                            </List.Item>
                        </div>
                        <List
                            dataSource={options}
                            renderItem={option => (
                                <List.Item
                                    onClick={() => handleCitySelection(option.value)}
                                    className='mx-md-2'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: selectedRegisterCities.includes(option.value) ? '#F4F4F4' : 'transparent',
                                        cursor: 'pointer',
                                        padding: '15px'
                                    }}
                                >
                                    {option.label}
                                    <Checkbox
                                        checked={selectedRegisterCities.includes(option.value)}
                                        onChange={() => handleCitySelection(option.value)}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#fff',
                        padding: '10px',
                        boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
                        zIndex: 1000
                    }}>
                        <Button className='col-12 col-md-2 bg-secondary' style={{ height: '50px', fontSize: 'large' }} type="primary" onClick={handleOk}>
                            Hazırdır
                        </Button>
                    </div>
                </Drawer>

            )}
        </div>
    );
};

export default RegisterCities;
