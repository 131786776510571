import { Button, Form, Input } from "antd"
import { useNavigate } from "react-router-dom";

const AdminLoginPage = () => {

  const navigate = useNavigate();
  const handleNavigation = (path: string) => {
    navigate(path);
};

  return (
    <div className="mt-4" style={{ maxWidth: 400, margin: "0 auto", padding: "20px" }}>
        <h6 className="text-secondary mb-5">Admin</h6>
      <Form
        name="login_form"
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "İstifadəçi adı girin!" }]}
        >
          <Input placeholder="İstifadəçi adı" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Şifrə girin!" }]}
        >
          <Input.Password placeholder="Şifrə" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" className='bg-warning mb-3' onClick={() => handleNavigation('/admin/home')} htmlType="submit" style={{ width: "100%" }}>
            Giriş
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AdminLoginPage
