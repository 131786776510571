import React, { useState } from "react";
import { Form, Input, Button, message, Upload, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { memberService } from "../services/member.service";
import { RegisterRequestType } from "../types/member.type";
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<any[]>([]);

  const formatPhoneNumber = (phoneNumber: string) => {
    const digits = phoneNumber.replace(/[^\d]/g, '');
    return `${digits.slice(0, 3)} ${digits.slice(3, 5)} ${digits.slice(5, 8)} ${digits.slice(8, 10)}`;
  };

  const onFinish = async (values: RegisterRequestType & { prefix: string }) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof RegisterRequestType];
        if (key === 'ProfileImage' && fileList.length > 0) {
          const file = fileList[0].originFileObj;
          if (file) {
            formData.append(key, file);
          }
        } else if (value !== null && value !== undefined) {
          if (key === 'PhoneNumber') {
            const fullPhoneNumber = `+994 ${values.prefix} ${formatPhoneNumber(value as string)}`.trim();
            formData.append(key, fullPhoneNumber);
          } else {
            formData.append(key, value as string);
          }
        }
      });

      const response = await memberService.register(formData);

      if (response.status === 200) {
        message.success("Qeydiyyat uğurla tamamlandı!", 1);
        navigate("/login");
      }
    } catch (error: any) {
      if (error.response) {
        const errorData = error.response.data;

        if (error.response.status === 400) {
          if (Array.isArray(errorData) && errorData.length > 0) {
            message.error(errorData[0], 1);
          } else if (typeof errorData.message === 'string') {
            message.error(errorData.message, 1);
          } else {
            message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
          }
        } else {
          message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
        }
      } else {
        message.error("Qeydiyyat zamanı bir problem yarandı.", 1);
      }
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ height: '45px', width: 70 }}>
        <Option value="50">050</Option>
        <Option value="51">051</Option>
        <Option value="55">055</Option>
        <Option value="70">070</Option>
        <Option value="77">077</Option>
        <Option value="10">010</Option>
        <Option value="99">099</Option>
        <Option value="60">060</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="mt-4 col-12 col-md-8" style={{ margin: "0 auto", padding: "20px" }}>
      <Form
        name="register_form"
        initialValues={{ remember: true, prefix: "50" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="ProfileImage"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file) => {
              setFileList([file]);
              return false;
            }}
            fileList={fileList}
            listType="picture-circle"
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}></Button>
          </Upload>
        </Form.Item>

        <div className="row">
          <Form.Item
            name="FirstName"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Adınızı daxil edin!" }]}
          >
            <Input placeholder="Ad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>

          <Form.Item
            name="LastName"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Soyadınızı daxil edin!" }]}
          >
            <Input placeholder="Soyad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item
            name="PhoneNumber"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Telefon nömrənizi daxil edin!" }]}
          >
            <Input
              addonBefore={prefixSelector}
              type="tel"
              placeholder="Telefon nömrəsi"
              style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }}
              maxLength={7}
            />
          </Form.Item>

          <Form.Item
            name="Password"
            className="col-12 col-md-6"
            rules={[{ required: true, message: "Şifrənizi daxil edin!" }]}
            hasFeedback
          >
            <Input.Password placeholder="Şifrə" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        <div className="row d-flex justify-content-end">
          <Form.Item
            name="ConfirmPassword"
            className="col-12 col-md-6"
            dependencies={['Password']}
            hasFeedback
            rules={[
              { required: true, message: "Şifrənizi təkrar daxil edin!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Şifrələr uyğun deyil!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Təkrar şifrə" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" className='bg-warning col-12 col-md-4 mb-3' htmlType="submit" style={{ height: '45px', borderRadius: '12px' }}>
            Qeydiyyatdan Keç
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterPage;
