import React, { useState, useEffect } from "react";
import { Button, Divider, Drawer, Form, Input, Radio, Select } from "antd";
import Categories from "./Categories";
import Cities from "./Cities";
import "bootstrap/dist/css/bootstrap.min.css";
import EmployeeCard from "../employee-cards/EmployeeCard";
import { useMediaQuery } from "react-responsive";
import Cookies from 'js-cookie';

const SearchBar: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    JSON.parse(Cookies.get("selectedCategories") || "[]")
  );
  const [selectedCities, setSelectedCities] = useState<string[]>(
    JSON.parse(Cookies.get("selectedCities") || "[]")
  );
  const [filterValues, setFilterValues] = useState<{}>(
    JSON.parse(Cookies.get("filterValues") || "{}")
  );
  const [countFilled, setCountFilled] = useState<number>(parseInt(Cookies.get("countFilledFields") || "0"));
  const [triggerSearch, setTriggerSearch] = useState(true);
  const [form] = Form.useForm();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    Cookies.set("selectedCategories", JSON.stringify(selectedCategories), { expires: 7 });
  }, [selectedCategories]);

  useEffect(() => {
    Cookies.set("selectedCities", JSON.stringify(selectedCities), { expires: 7 });
  }, [selectedCities]);

  useEffect(() => {
    Cookies.set("filterValues", JSON.stringify(filterValues), { expires: 7 });
  }, [filterValues]);

  const handleCategoriesChange = (categories: string[]) => {
    setSelectedCategories(categories);
  };

  const handleCitiesChange = (cities: string[]) => {
    setSelectedCities(cities);
  };

  const updateCountFilledFields = (values: {}) => {
    const count = Object.values(values).filter(value => value !== undefined && value !== null && value !== "").length;
    setCountFilled(count);
    Cookies.set("countFilledFields", count.toString(), { expires: 7 });
  };

  useEffect(() => {
    form.setFieldsValue(filterValues);
    updateCountFilledFields(filterValues);
  }, [form, filterValues]);

  const handleSearch = () => {
    const values = form.getFieldsValue();
    setFilterValues(values);
    updateCountFilledFields(values);
    setTriggerSearch(true);
    setIsDrawerOpen(false);
  };

  const handleReset = () => {
    form.resetFields();
    setSelectedCategories([]);
    setSelectedCities([]);
    setFilterValues({});
    setTriggerSearch(true);
    setIsDrawerOpen(false);
    Cookies.remove("selectedCategories");
    Cookies.remove("selectedCities");
    Cookies.remove("filterValues");
    Cookies.remove("countFilledFields");
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  return (
    <>
      <div className="container container-search-bar p-1 p-md-3 rounded" style={{ backgroundColor: '#a3b3ae' }}>
        <div className="row align-items-center p-0 m-0 mt-2">
          <div className="col-12 col-md-6 mb-2 mb-md-1 mb-md-0">
            <Categories onChange={handleCategoriesChange} />
          </div>
          <div className="col-12 col-md-6 mb-md-1 mb-md-0">
            <Cities onChange={handleCitiesChange} />
          </div>
          <div className="row d-flex p-0 m-0 mt-2 mb-2">
            <div className="col-12 col-md-6">
              <div className="d-flex justify-content-between">
                <Button
                  className="bg-secondary text-light col-6 col-md-4"
                  type="primary"
                  style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}
                  onClick={toggleDrawer}
                >
                  Filter {countFilled > 0 ? `(${countFilled})` : '(0)'}
                </Button>
                <Button
                  className="bg-warning text-light col-6 col-md-4"
                  type="primary"
                  style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}
                  onClick={handleSearch}
                >
                  Axtar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Filter"
        placement="left"
        onClose={toggleDrawer}
        open={isDrawerOpen}
        size={isMobile ? "large" : "default"}
      >
        <Form form={form} layout="vertical">
          <Form.Item className="col-12" name="firstName">
            <Input size="large" placeholder="Ad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
          <Form.Item className="col-12" name="lastName">
            <Input size="large" placeholder="Soyad" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
          </Form.Item>
          <div className="d-flex justify-content-between">
            <Form.Item name="MinAge" style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}>
              <Input size="large" placeholder="Minimum Yaş" type="number" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
            </Form.Item>
            <h5 className="mt-2 text-secondary">-</h5>
            <Form.Item name="MaxAge" style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}>
              <Input size="large" placeholder="Maksimum Yaş" type="number" style={{ height: '45px', borderRadius: '12px', fontSize: 'medium' }} />
            </Form.Item>
          </div>
          <Form.Item
            name="Gender"
          >
            <Radio.Group optionType="button" buttonStyle="solid" className="col-12 custom-radio-group">
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '12px 0 0 12px', textAlign: 'center', paddingTop: '2px' }} value={1}>Kişi</Radio.Button>
              <Radio.Button style={{ width: '50%', height: 40, borderRadius: '0 12px 12px 0', textAlign: 'center', paddingTop: '2px' }} value={2}>Qadın</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="Experience"
            className="experience"
          >
            <Select placeholder="Təcrübə" style={{ width: "100%", height: '45px' }}>
              <Select.Option value={1}>1 ildən aşağı</Select.Option>
              <Select.Option value={2}>1 ildən 3 ilə qədər</Select.Option>
              <Select.Option value={3}>3 ildən 5 ilə qədər</Select.Option>
              <Select.Option value={4}>5 ildən artıq</Select.Option>
            </Select>
          </Form.Item>
        </Form>

        <Divider />

        <div className="d-flex justify-content-between">
          <Button
            className="bg-secondary text-light col-6 col-md-4"
            type="primary"
            style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}
            onClick={handleReset}
          >
            Sıfırla
          </Button>
          <Button
            className="bg-warning text-light col-6 col-md-4"
            type="primary"
            style={{ height: '45px', width: '48%', borderRadius: '12px', fontSize: 'medium' }}
            onClick={handleSearch}
          >
            Hazırdır
          </Button>
        </div>
      </Drawer>

      <div className="p-2">
        <EmployeeCard
          selectedCategories={selectedCategories}
          selectedCities={selectedCities}
          filterValues={filterValues}
          triggerSearch={triggerSearch}
          setTriggerSearch={setTriggerSearch}
        />
      </div>
    </>
  );
};

export default SearchBar;