import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { message } from "antd";
import MemberCard from "../components/employee-cards/MemberCard";
import { useMediaQuery } from "react-responsive";

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const savedFavorites = Cookies.get('favorites') ? JSON.parse(Cookies.get('favorites')!) : [];
    setFavorites(savedFavorites);
  }, []);

  const handleFavoriteToggle = (member: any) => {
    const updatedFavorites = favorites.filter((fav: any) => fav.id !== member.id);
    Cookies.set('favorites', JSON.stringify(updatedFavorites));
    setFavorites(updatedFavorites);
    message.error('Favorilərdən çıxarıldı', 1);
  };

  return (
    <div className={`favorites-container p-2 ${isMobile ? 'mobile' : 'desktop'}`}>
      {favorites.length > 0 ? (
        favorites.map(member => (
          <MemberCard
            key={member.id}
            member={member}
            isFavorite={true}
            onFavoriteToggle={handleFavoriteToggle}
          />
        ))
      ) : (
        ''
      )}
    </div>
  );
};

export default FavoritesPage;
