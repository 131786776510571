import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select } from 'antd';
import { categoryService } from '../../../../services/category.service';
import { AllCategoriesResponseType, Category } from '../../../../types/category.type';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const AdminCategories: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editingCategory, setEditingCategory] = useState<Category | null>(null);
    const [form] = Form.useForm();

    const fetchCategories = async () => {
        try {
            const response = await categoryService.getAllCategories();
            const result: AllCategoriesResponseType = response.data;
            if (result.isSucceed) {
                setCategories(result.data);
                setAllCategories(result.data);
            } else {
            }
        } catch (err) {
        } finally {
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const showModal = (category?: Category) => {
        setEditingCategory(category || null);
        form.resetFields();
        if (category) {
            form.setFieldsValue(category);
        }
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            if (editingCategory) {
                await categoryService.updateCategory(editingCategory.id, values);
            } else {
                await categoryService.createCategory(values);
            }
            setIsModalVisible(false);
            fetchCategories();
        } catch (err) {
            console.error('Failed to submit:', err);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDelete = async (id: any) => {
        try {
            await categoryService.deleteCategory(id);
            fetchCategories();
        } catch (err) {
            console.error('Failed to delete:', err);
        }
    };

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            key: 'action',
            render: (_: any, record: any) => (
                <>
                    <Button className='m-2' icon={<EditOutlined />} onClick={() => showModal(record)}></Button>
                    <Button className='m-2' icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)} danger></Button>
                </>
            ),
        },
    ];

    return (
        <>
            <div className='d-flex justify-content-end mb-3'>
                <Button type="primary" className='bg-warning' onClick={() => showModal()}>Yeni</Button>
            </div>
            <Table
                dataSource={categories}
                columns={columns}
                rowKey="id"
            />
            <Modal
                title={editingCategory ? 'Redaktə' : 'Yeni'}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Ad"
                        rules={[{ required: true, message: '' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="parentId"
                        label="Mənsub olduğu iş"
                    >
                        <Select  allowClear>
                            {allCategories.map(cat => (
                                <Select.Option key={cat.id} value={cat.id}>
                                    {cat.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AdminCategories;
