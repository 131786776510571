import { MemberFilters } from "../types/member.type";
import axios from "./axios/_axios";
import qs from "qs";

export const memberService = {
  getMember: (pageNumber: number, pageSize: number): Promise<any> => {
    return axios.get(`/apimembers`, {
      params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
      paramsSerializer: (params) => qs.stringify(params, { indices: true }),
    });
  },

  login: (phoneNumber: string, password: string) => {
    return axios.post(`/api/members/login`, { phoneNumber, password });
  },

  register: (data: FormData) => {
    return axios.post(`/api/members/register`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  workerRegister: (data: FormData) => {
    return axios.post(`/api/members/worker-register`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getMemberById: (id: string) => {
    return axios.get(`/api/members/${id}`);
  },

  searchMasters: (pageNumber: number, pageSize: number, filters: MemberFilters = {}): Promise<any> => {
    return axios.get(`/api/members/search-masters`, {
      params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        ...filters,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', indices: false }),
    });
  },

  getMemberDetails: () => {
    return axios.get(`/api/members/details`);
  },

  getMemberAttachments: (memberId: string): Promise<string[]> => {
    return axios.get(`/api/members/${memberId}/attachments`)
      .then(response => response.data.data)
      .catch(() => []);
  },

  saveMemberRating: (memberId: string, ratingValue: number, reviewText: string) => {
    return axios.post(`/api/members/add-rating`, {
      memberId,
      ratingValue,
      reviewText,
    })
      .then(response => response.data)
      .catch((error: any) => {
        const errorMessage = error.response?.data?.data?.message || 'Paun vermisiniz artıq!';
        console.error('API Error:', errorMessage);
        throw new Error(errorMessage);
      });
  },

  getMemberRatings: (memberId: string): Promise<any> => {
    return axios.get(`/api/members/${memberId}/ratings`);
  },

  updateMember: (memberId: string, data: FormData): Promise<any> => {
    return axios.put(`/api/members/${memberId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  updateMaster: (memberId: string, data: FormData): Promise<any> => {
    return axios.put(`/api/members/update-master/${memberId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  uploadMemberAttachment: (memberId: string, formData: FormData) => {
    return axios.post(`/api/members/${memberId}/attachments`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  deleteMemberAttachment: (memberId: string, attachmentId: string) => {
    return axios.delete(`/api/members/${memberId}/attachments/${attachmentId}`);
  },
  upgradeToMaster: (memberId: string, data: any) => {
    const formData = new FormData();

    formData.append('CategoryId', data.categoryId);
    formData.append('BirthDate', data.birthDate);
    formData.append('Gender', data.gender.toString());
    formData.append('Experience', data.experience.toString());
    formData.append('Description', data.description || '');

    data.cities.forEach((city: string) => {
      formData.append('Cities', city);
    });

    data.additionalCategories.forEach((category: string) => {
      formData.append('Categories', category);
    });

    if (data.attachments) {
      data.attachments.forEach((file: File) => {
        formData.append('Attachments', file);
      });
    }

    return axios.put(`/api/members/upgrade-to-master/${memberId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => response.data)
      .catch((error: any) => {
        const errorMessage = error.response?.data?.data?.message || 'Upgradeda xeta baş verdi!';
        console.error('API Error:', errorMessage);
        throw new Error(errorMessage);
      });
  },
  resetPassword: (currentPassword: string, newPassword: string, confirmPassword: string) => {
    return axios.post('/api/members/reset-password', { currentPassword, newPassword, confirmPassword });
  }

};
